import { animateFade, animateFadeUp } from "../../../assets/src/js/includes/animations";

const blocks = document.querySelectorAll(
    `.wp-block-blufish-agent-bio`
);

if (blocks.length > 0) {
    blocks.forEach((block) => {
        const bio = block.querySelectorAll(`.js-bio .content > *`);
        const sidebar = block.querySelector(`.js-sidebar`);
        const items = sidebar.querySelectorAll(`.heading, .content, .image`);

        animateFadeUp(bio, {
            stagger: 0.1,
        });


        let mm = gsap.matchMedia();

        mm.add("(min-width: 901px)", () => {
            animateFade(items, {
                x: 24,
                stagger: 0.1,
            });
        });

        mm.add("(max-width: 900px)", () => {
            animateFade(items, {
                x: -24,
                stagger: 0.1,
            });
        });
    });
}